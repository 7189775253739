import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { ScatterplotLayer, ArcLayer } from '@deck.gl/layers';
import {scatterLayerData, arcLayerData} from './MapData.js';
import {Button, Card, CardContent, CardActions, Typography} from '@material-ui/core';

const TOKEN = "pk.eyJ1IjoicmR1YW4iLCJhIjoiY2pxd3N4ZjJ1MTlvYjQyczNmY3RoNzAwaSJ9.Q5_KlpYoMed6oneHPs4K_g";

const Map = props => {

    const [viewport, setViewport] = useState(
        {
            longitude: -114.072411,
            latitude: 51.640872,
            zoom: 11,
            pitch: 50,
            bearing: 0
        }
    );
    const [stage, setStage] = useState(0);

    const scatterLayer = new ScatterplotLayer({
        id: 'scatterplot-layer',
        data: scatterLayerData,
        pickable: true,
        opacity: 0.8,
        stroked: true,
        filled: true,
        radiusScale: 6,
        radiusMinPixels: 1,
        radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        getPosition: d => d.coordinates,
        getRadius: d => 100,
        getColor: d => [57, 255, 20, 150],
        getLineColor: d => [57, 255, 20],
    });

    const arcLayer = new ArcLayer({
        id: "arc-layer",
        data: stage === 0 ? [] : arcLayerData.slice(0, stage),
        pickable: true,
        getStrokeWidth: 10,
        getSourcePosition: d => d.from.coordinates,
        getTargetPosition: d => d.to.coordinates,
        getSourceColor: d => [355, 193, 7],
        getTargetColor: d => [255, 193, 7]
    });

    const calcTime = (lat_1, lng_1, lat_2, lng_2) =>
        Math.pow((lng_2 - lng_1) ** 2 + (lat_2 - lat_1) ** 2, 0.5);

    const stageChangeHeader = ( previousStage, stage ) => {
        setStage(stage);

        setViewport({
            longitude: scatterLayerData[stage].coordinates[0],
            latitude: scatterLayerData[stage].coordinates[1],
            zoom: 11,
            pitch: 50,
            bearing: 0,
            transitionDuration: (stage === 4 || stage === 5 ? 1000 : 6500) * calcTime(...scatterLayerData[previousStage].coordinates, ...scatterLayerData[stage].coordinates),
            transitionInterpolator: new FlyToInterpolator()
        })
    };


    return (
        <div style={{height: 'calc(100% - 64px)'}}>
            <ReactMapGL
                width="100%"
                height="100%"
                {...viewport}
                onViewportChange={(viewport) => setViewport(viewport)}
                mapboxApiAccessToken={TOKEN}
            >
                <DeckGL viewState={viewport} layers={[scatterLayer, arcLayer]}/>
                <Card style={{width: '200px', position: 'fixed', bottom: '0', right: '0', margin: '0 2rem 2rem 0'}}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {scatterLayerData[stage].name}
                        </Typography>
                        <Typography style={{marginBottom: 12}} color="textSecondary">
                            {scatterLayerData[stage].address}
                        </Typography>
                        <CardActions style={{paddingTop: '1rem'}}>
                            <Button color='primary' variant='contained' disabled={stage === 0} onClick={() => (stageChangeHeader( stage, stage - 1 ))}>Previous</Button>
                            <Button color='primary' variant='contained' disabled={stage === 5} onClick={() => (stageChangeHeader( stage,stage + 1 ))}>Next</Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </ReactMapGL>
        </div>
    );
};

Map.propTypes = {

};

export default Map;
