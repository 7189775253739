import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@material-ui/core'
import logo from '../../resources/oldsLogo.svg';
import graindiscoveryLogo from '../../resources/grainDiscoveryLogo.png'
import oldsCollegeLogo from '../../resources/oldsCollege.png'

const About = props => {
    return (
        <div style={{ backgroundColor: '#393a3b', height: 'calc(100% - 64px)', textAlign: 'center'}}>
            <div style={{width: '100%', margin: '0', textAlign: 'left', color: 'white', padding: '1rem 2rem 0 2rem'}}>
                <h3 style={{fontWeight: 'lighter', fontSize: '2rem'}}>About</h3>
                <p>
                    Modern agriculture is undergoing an important shift: away from a commodities business, where each bushel is considered identical, to an ingredients business, where customers demand distinction.
                </p>
                <p>
                    With our food passing through many intermediaries on its journey from the farm, traceability and provenance is often lost. However, consumers are increasingly interested in knowing where their food comes from, how it’s made, and how it ended up on their dinner plate – or in this case in their beer!
                </p>
                <p>
                    ​

                    This project is the first step in creating an end-end digital solution to track agricultural commodities through the value chain. This project provided an opportunity to pilot blockchain technology and gain critical insight how it can be expanded further by the Canadian agriculture industry.
                </p>
            </div>
            <div style={{width: '100%', marginTop: '3rem'}}>
                <Grid container justify="center" spacing={2} style={{flexGrow: 1}}>
                    <Grid item xs>
                        <img src={graindiscoveryLogo} style={{height: '100px', marginBottom: '2rem'}}/>
                        <Container style={{textAlign: 'left', color: 'white'}}>
                            <p>
                                An agricultural technology company transforming the supply chain and rebuilding consumer trust in where our food comes from. Grain Discovery’s digital ecosystem addresses the current disconnect between farmers, intermediaries and consumers by focusing on price discovery and traceability- longtime industry pain points.  Our competitive advantage lies in our use of blockchain technology to facilitate contract settlement, add security, and trace authenticity of a commodity as it moves through the supply chain.
                            </p>
                        </Container>
                    </Grid>
                    <Grid item xs>
                        <img src={logo} style={{height: '100px', marginBottom: '2rem'}}/>
                        <Container style={{textAlign: 'left', color: 'white'}}>
                            <p>
                                The brewing industry in Alberta and Canada is growing rapidly. Customer demand has led to an explosion in the numbers of locally owned and operated craft breweries. The demand for locally produced brews has led to establishing the Olds College Brewmaster and Brewery Operations Management program, and the Olds College Brewery and Brewery Retail Store.
                            </p>
                            <p>
                                As a social enterprise the brewery is not profit driven. All revenue from beer sales goes toward supporting education and in doing so, the brewing industry. The goal is to train the next generation right and to produce the best beer possible while doing so.
                            </p>
                        </Container>
                    </Grid>
                    <Grid item xs>
                        <img src={oldsCollegeLogo} style={{height: '100px', marginBottom: '2rem'}}/>
                        <Container style={{textAlign: 'left', color: 'white'}}>
                            <p>
                                In the summer of 2018, Olds College launched the Olds College Smart Farm, an exciting initiative that is transforming the College’s existing farm into a farm of the future. By incorporating the latest technologies aimed at improving productivity, while efficiently and sustainably using resources, the Smart Farm is creating a cutting-edge learning environment for students and life-long learners.
                            </p>
                        </Container>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

About.propTypes = {

};

export default About;
