import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { FaSeedling, FaBeer } from 'react-icons/fa';
import { GiWheat, GiFarmTractor, GiGrain, GiTestTubes, GiHops } from 'react-icons/gi'
import { AiOutlineClockCircle } from 'react-icons/ai';
import {Link, Button} from '@material-ui/core';
import stageAboutInfo from '../../resources/stageAboutInfo.json';
import InfoModal from './components/InfoModal.jsx';
import agronomicSheet from '../../resources/documents/agronomic_sheet.pdf';
import howIsSeedCertified from '../../resources/documents/how_is_seed_certified.pdf';
import cropPlan from '../../resources/documents/decisive_farming_crop_plan.pdf';
import precipitation from '../../resources/documents/precipitation.jpg';
import yieldMap from '../../resources/documents/yield_map.pdf';
import hopProfile from '../../resources/documents/hop_profile.png';
import temperature from '../../resources/documents/temperature.jpg';

const Timeline = props => {

    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState('');
    const [infoModalText, setInfoModalText] = useState([]);

    const openModalHandler = (title, text) => {
        setInfoModalTitle(title);
        setInfoModalText(text);
        setDisplayInfoModal(true);
    };

    const closeModalHandler = () => {
        setDisplayInfoModal(false);
        setInfoModalTitle('');
        setInfoModalText([]);
    };

    const viewPDF = (file) => {
        window.open(file);
    };

    return (
        <div style={{backgroundColor: '#393a3b', height: 'calc(100% - 64px)'}}>
            <div style={{height: '100%', overflowY: 'scroll'}}>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<FaSeedling />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_1.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Variety</h3>
                        <p>
                            The first mile of the beer journey is the barley variety! Developed by Bryan Harvey from Crop Development Centre at the University of Saskatchewan, CDC Copeland is one of the most popular Canadian malting varieties with a 44% marketshare.
                        </p>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Barley Variety:
                            </span>
                            <span>
                                {' CDC Copeland'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Agronomic Sheet:
                            </span>
                            <span>
                                <Link style={{paddingLeft: '0.5rem'}} onClick={() => viewPDF(agronomicSheet)}>
                                    Click Here
                                </Link>
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Fun Fact:
                            </span>
                            <span>
                                {' CDC Copeland has been selected as “Seed of the Year” award in 2020.'}
                            </span>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<GiWheat/>}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_2.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Certified Seed</h3>
                        <div>
                            <span>
                                <p>
                                    Canada’s seed certification system ensures that only high quality, true-to-variety and pure seed is produced. To learn more about seed certification
                                </p>
                            </span>
                            <span>
                                <Link onClick={() => viewPDF(howIsSeedCertified)}>
                                    Click Here
                                </Link>
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Retailer:
                            </span>
                            <span>
                                {' Westway Farms Ltd.'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Location:
                            </span>
                            <span>
                                {' Didsbury, AB'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Crop Year
                            </span>
                            <span>
                                {' 2018'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Lot Number:
                            </span>
                            <span>
                                {' 1419-9-182961'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Crop Cert Number:
                            </span>
                            <span>
                                {' 18-8046341-405'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <Button variant='contained' color='primary' onClick={() => openModalHandler(stageAboutInfo['1'].title, stageAboutInfo['1'].text)} style={{fontWeight: 'lighter'}}>
                                About
                            </Button>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<GiWheat />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_3.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Olds College Farm - Planting</h3>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Field Id:
                            </span>
                            <span>
                                {' 26342'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Field History:
                            </span>
                            <span>
                                {' 2018: Wheat, 2017: Canola'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Planted:
                            </span>
                            <span>
                                {' 24 May 2019'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Acres:
                            </span>
                            <span>
                                {' 191.15'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Variety:
                            </span>
                            <span>
                                {' CDC Copeland'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Seed Rate:
                            </span>
                            <span>
                                {' 165.8 lb/acre'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Fertilizer Blend:
                            </span>
                            <span>
                                {' 22.1 - 17.2 - 16.0 - 0.0'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Rate:
                            </span>
                            <span>
                                {' 218 lb/acre'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Decisive Farming Crop Plan:
                            </span>
                            <span>
                                <Link onClick={() => viewPDF(cropPlan)}>
                                    Click Here
                                </Link>
                            </span>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<GiFarmTractor />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_4.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Growing Season</h3>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Post Emergent
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' June 24, 2019'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Applications:
                            </span>
                        </div>
                        <div>
                            Tundra 810 mL/Acre
                        </div>
                        <div>
                            AMS 510 mL/Acre
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Climatic Conditions:
                            </span>
                            <span>
                                <Link onClick={() => viewPDF(precipitation)}>
                                    Precipitation
                                </Link>
                            </span>
                            <span>
                                {' & '}
                            </span>
                            <span>
                                <Link onClick={() => viewPDF(temperature)}>
                                    Temperature
                                </Link>
                            </span>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<GiGrain />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_5.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Harvest</h3>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Harvested:
                            </span>
                            <span>
                                {' September 24, 2019'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Acres:
                            </span>
                            <span>
                                {' 65'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Yield:
                            </span>
                            <span>
                                {' 110 bu/acre'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Yield Map:
                            </span>
                            <span>
                                <Link onClick={() => viewPDF(yieldMap)}>
                                    Click Here
                                </Link>
                            </span>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<GiTestTubes />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_6.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Quality Testing</h3>
                        <p>
                            Barley that is malting quality must meet strict criteria to be accepted. Maintaining controls on these quality factors is necessary to ensure good processing efficiency. A premium is paid to farmers for high quality to compensate for the extra diligence.
                        </p>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Testing:
                            </span>
                            <span>
                                {' Richardson Pioneer'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Location:
                            </span>
                            <span>
                                {' Olds, AB'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' September 27, 2019'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Protein
                            </span>
                            <span>
                                {' 9.9%'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Moisture
                            </span>
                            <span>
                                {' 14.4%'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Test Weight
                            </span>
                            <span>
                                {' 309'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Plumpness
                            </span>
                            <span>
                                {' 91%'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Chitting
                            </span>
                            <span>
                                {' 0.3%'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Germination
                            </span>
                            <span>
                                {' 99%'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                P&B
                            </span>
                            <span>
                                {' 1.5%'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <Button variant='contained' color='primary' onClick={() => openModalHandler(stageAboutInfo['6'].title, stageAboutInfo['6'].text)} style={{fontWeight: 'lighter'}}>
                                About
                            </Button>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<AiOutlineClockCircle />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_7.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Malting</h3>
                        <p>
                            A three-stage process (steeping, germination and drying), that has remained practically unchanged for thousands of years
                        </p>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Maltster:
                            </span>
                            <span>
                                {' Red Shed Malting'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Location:
                            </span>
                            <span>
                                {' Penhold, AB'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Batch:
                            </span>
                            <span>
                                {' 1320'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Steeping
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' December 9, 2019'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Moisture:
                            </span>
                            <span>
                                {' 44.96%'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Germination
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' 11 - 15 December'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Total Hours:
                            </span>
                            <span>
                                {' 94'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Drying
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Kiln:
                            </span>
                            <span>
                                {' December 15, 2019'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Moisture:
                            </span>
                            <span>
                                {' 4.7%'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Colour:
                            </span>
                            <span>
                                {' 1.9 srm'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Friability:
                            </span>
                            <span>
                                {' 87'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <Button variant='contained' color='primary' onClick={() => openModalHandler(stageAboutInfo['7'].title, stageAboutInfo['7'].text)} style={{fontWeight: 'lighter'}}>
                                About
                            </Button>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<GiHops />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_8.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Hops</h3>
                        <p>
                            The hop flower gives beer its bitterness and flavouring, imparting floral, fruity, or citrus flavours and aromas. As a bonus, hops are a preservative, and extend the life of beer.
                        </p>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Variety:
                            </span>
                            <span>
                                {' Sasquatch'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Location:
                            </span>
                            <span>
                                {' Abbotsford, BC'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Harvest:
                            </span>
                            <span>
                                {' September 12, 2018'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Batch:
                            </span>
                            <span>
                                {' I-8380'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Hop Profile:
                            </span>
                            <span>
                                <Link onClick={() => viewPDF(hopProfile)}>
                                    Click Here
                                </Link>
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span >
                                {'Sasquatch is an indigenous wild hop with orange, tea, lemon citrus notes. Discovered in the Pemberton Valley (BC), the first commercial harvest was in 2018 and is now grown on Hops Connect farms in Abbotsford (BC) and Leamington (ON). '}
                            </span>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<FaBeer />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_9.jpg'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Brewing</h3>
                        <p>
                            Malt barley is the key ingredient in beer and provides both starch and enzymes necessary to produce the fermentable sugars which yeast then turn into alcohol. It also provides the colour and flavor which adds to the profile of each beer.
                        </p>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Location:
                            </span>
                            <span>
                                {' Olds College Brewery'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Brew #:
                            </span>
                            <span>
                                {' 385'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Style:
                            </span>
                            <span>
                                {' Pale Lager'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Brew
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' February 20, 2020'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                On Boil Volume:
                            </span>
                            <span>
                                {' 1,310 L'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Evaporation:
                            </span>
                            <span>
                                {' 3.82%'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Fermentation
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' February 20 - March 14, 2020'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Volume:
                            </span>
                            <span>
                                {' 1,170 L'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Yeast:
                            </span>
                            <span>
                                {' Reused from prior brew CD #381​'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                ABV:
                            </span>
                            <span>
                                {' 5.14%'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Lagering
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' March 14 - April 24, 2020'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Maturation
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' April 24 - June 3, 2020'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Volume:
                            </span>
                            <span>
                                {' 872 L'}
                            </span>
                        </div>
                        <br/>
                        <div>
                            <Button variant='contained' color='primary' onClick={() => openModalHandler(stageAboutInfo['9'].title, stageAboutInfo['9'].text)} style={{fontWeight: 'lighter'}}>
                                About
                            </Button>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element"
                        contentStyle={{ background: '#fff', color: '#000' }}
                        iconStyle={{ background: '#f7c12b', color: '#228c22' }}
                        icon={<FaBeer />}
                    >
                        <img src={'https://storage.googleapis.com/gd-public-assets/olds_college/stage_10.png'} style={{width: '100%'}}/>
                        <h3 className="vertical-timeline-element-title" style={{fontWeight: 'lighter', fontSize: '2rem', color:'#f9c14f'}}>Canning</h3>
                        <p>
                            The last mile of the journey! Intentionally light in body and colour, this delicate beer presents soft notes of bread and grain. Utilizing Sasquatch® hops there are background hints of cedar, combined with floral and citrus elements, which balance the malty sweetness. The crisp, dry finish is reassuring and comforting.
                        </p>
                        <br/>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Location:
                            </span>
                            <span>
                                {' Olds College Brewery'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Date:
                            </span>
                            <span>
                                {' June 3, 2020'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Batch:
                            </span>
                            <span>
                                {' 385'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Volume:
                            </span>
                            <span>
                                {' 872 L'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Cans:
                            </span>
                            <span>
                                {' 1,021'}
                            </span>
                        </div>
                        <div>
                            <span style={{fontWeight: 'bold'}}>
                                Kegs:
                            </span>
                            <span>
                                {' 9 x 50L & 3 x 20L'}
                            </span>
                        </div>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </div>
            <InfoModal title={infoModalTitle} text={infoModalText} open={displayInfoModal} handleClose={closeModalHandler}/>
        </div>
    );
};

Timeline.propTypes = {

};

export default Timeline;
