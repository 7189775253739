import ReactDOM from 'react-dom';
import React from 'react';

import App from './App.jsx'


const render = () => {
    ReactDOM.render(
        <App/>,
        document.getElementById('root')
    );
};

render();
