import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Drawer, AppBar, Toolbar, List, IconButton, Typography, Link, Grid} from '@material-ui/core'
import { Menu, ChevronLeft } from '@material-ui/icons';

import NavigationItems from './components/NavigationItems.jsx';

import logo from '../resources/oldsLogo.svg'
import grainDiscoveryLogo from '../resources/grainDiscoveryLogoWhite.png'
import clsx from 'clsx';

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 12,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: '#4C505D'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        backgroundColor: '#4C505D'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100%'
    },
}));

const Layout = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const Component = props.component;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                style={{backgroundColor: '#f9c14f'}}
            >
                <Toolbar style={{paddingLeft: '1rem'}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <Menu style={{ color: '#393a3b'}}/>
                    </IconButton>
                    <img src={logo} style={{height: '45px'}}/>
                    <Typography variant="h4" component="h2" style={{paddingLeft: '1.5rem', fontWeight: 'lighter', flex: 1 }}>
                        Barley Trail Field to Glass
                    </Typography>
                    <Link href="https://graindiscovery.com/home" style={{color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <Grid container justify='center' spacing={2}>
                            <Grid item style={{marginTop: '0.5rem'}}>
                                Connected By Grain Discovery
                            </Grid>
                            <Grid item>
                                <img src={grainDiscoveryLogo} style={{height: '30px'}}/>
                            </Grid>
                        </Grid>
                    </Link>
                </Toolbar>

            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeft />
                    </IconButton>
                </div>
                <List>
                    <NavigationItems/>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Component {...props.component}/>
            </main>
        </div>
    );
};

export default Layout;

