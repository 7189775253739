import React, { Fragment } from 'react';
import { NavLink } from "react-router-dom"
import { ListItem, ListItemIcon, ListItemText, Divider, IconButton, List} from '@material-ui/core';
import { Map, Timeline, Info } from '@material-ui/icons';


//TODO: onClick links removed from nav

export default function() {
    return (
        <Fragment>
            <NavLink to='/' replace style={{ color: '#D1D3E0'}}>
                <ListItem button key={"Timeline"}>
                    <ListItemIcon>
                        <Timeline style={{ color: '#D1D3E0'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Timeline"}/>
                </ListItem>
            </NavLink>
            <Divider />
            <NavLink to='/map' replace style={{ color: '#D1D3E0'}}>
                <ListItem button key={"Map"}>
                    <ListItemIcon>
                        <Map style={{ color: '#D1D3E0'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Map"} />
                </ListItem>
            </NavLink>
            <Divider />
            <NavLink to='/about' replace style={{ color: '#D1D3E0'}}>
                <ListItem button key={"About"}>
                    <ListItemIcon>
                        <Info style={{ color: '#D1D3E0'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"About"} />
                </ListItem>
            </NavLink>
        </Fragment>
    );
};

