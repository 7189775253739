import React, { Component } from "react";
import Layout from './layout/Layout.jsx';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Map from './views/map/Map.jsx';
import Timeline from './views/timeline/Timeline.jsx';
import About from './views/about/About.jsx';

class App extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BrowserRouter basename='/'>
                <div style={{height: '100vh'}}>
                    <Switch>
                        <Route exact path="/">
                            <Layout  component={Timeline}/>
                        </Route>
                        <Route path="/map">
                            <Layout component={Map}/>
                        </Route>
                        <Route path="/about">
                            <Layout component={About}/>
                        </Route>
                        <Redirect from='/qr8888' to='/'/>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
