export const scatterLayerData = [
    {
        name:  'Seed Grower',
        address: 'Range Road 13, Alberta, Canada',
        coordinates: [-114.072411, 51.640872]
    },
    {
        name: 'Olds College Farm',
        address: 'Alberta',
        coordinates: [-114.0165, 51.77402]
    },
    {
        name: 'Red Shed Malting',
        address: 'Range Road 275, Alberta',
        coordinates: [-113.8345, 52.12888]
    },
    {
        name: 'Quality Testing',
        address: 'Richardson Pioneer Olds Elevator, Alberta',
        coordinates: [-114.087055, 51.81560]
    },
    {
        name: 'Hops',
        address: 'Cole Road, Abbotsford, British Columbia ',
        coordinates: [-122.184153, 49.036055]
    },
    {
        name: 'Olds College Brewery',
        address: '4601 46 Avenue, Alberta',
        coordinates: [-114.09523, 51.79412]
    }
];

export const arcLayerData = [
    {
        from: {
            coordinates: [-114.072411, 51.640872]
        },
        to: {
            coordinates: [-114.0165, 51.77402]
        }
    },
    {
        from: {
            coordinates: [-114.0165, 51.77402]
        },
        to: {
            coordinates: [-113.8345, 52.12888]
        }
    },
    {
        from: {
            coordinates: [-113.8345, 52.12888]
        },
        to: {
            coordinates: [-114.087055, 51.81560]
        }
    },
    {
        from: {
            coordinates: [-114.087055, 51.81560]
        },
        to: {
            coordinates: [-122.184153, 49.036055]
        }
    },
    {
        from: {
            coordinates: [-122.184153, 49.036055]
        },
        to: {
            coordinates: [-114.09523, 51.79412]
        }
    }
];
