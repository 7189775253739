import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';

const InfoModal = props => {

    const paragraphs = props.text.map((text) => {
            return <p>
                {text}
            </p>
    });

    return (
        <div style={{width: '50%'}}>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}
            >
                <Paper style={{width: '50%', padding: '2rem', height: '50%', overflowY: 'auto'}}>
                    <h2 style={{fontWeight: 'lighter', fontSize: '2rem', color: '#268c23', marginBottom: '2rem'}}>
                        {props.title}
                    </h2>
                    <div>
                        {paragraphs}
                    </div>
                </Paper>
            </Modal>
        </div>
    );
};

InfoModal.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default InfoModal;
